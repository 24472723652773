import {
    DISPLAY_PRODUCT_PRICES_IN_CATALOG_BOTH,
    DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX,
    DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX
} from 'Component/ProductPrice/ProductPrice.config';
import { formatPrice } from 'Util/Price';
import { getStore } from 'Util/Store';

/** @namespace Pwasaas/Util/Price/GetPrice/getLowestPriceTiersPrice */
export const getLowestPriceTiersPrice = (price_tiers) => {
    const lowestValue = price_tiers
        .reduce((acc, { final_price: { value } }) => (acc < value ? acc : value), price_tiers[0].final_price.value);

    return { final_price: { value: lowestValue } };
};

/** @namespace Pwasaas/Util/Price/GetPrice/_getTierQty1 */
export const _getTierQty1 = (price_tiers) => {
    const use_tier1 = getStore().getState().SaasConfigReducer.config?.use_tier1 || null;

    // bbv get tier with qty 1
    if (use_tier1 === '1') {
        return price_tiers.find(({ quantity }) => quantity === 1);
    }

    return price_tiers[0];
};

/** @namespace Pwasaas/Util/Price/GetPrice/getFinalPrice */
export const getFinalPrice = (props, isProductCard) => {
    const {
        price: {
            minimum_price: {
                default_final_price: {
                    value: defaultFinalPriceValue = 0
                } = {},
                final_price: {
                    value: minimalFinalPriceValue
                } = {},
                final_price_excl_tax: {
                    value: minimalFinalPriceExclTaxValue
                } = {}
            } = {}
        } = {},
        price_tiers = [],
        displayTaxInPrice = ''
    } = props;

    // disabled due to illogical behaviour
    // if (price_tiers.length) {
    //     return getLowestPriceTiersPrice(price_tiers, priceCurrency);
    // }

    const finalPrice = displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX
        ? minimalFinalPriceExclTaxValue
        : minimalFinalPriceValue;

    if (!price_tiers.length) {
        // Make sure the lowest price is not price displayed excl. tax
        if (displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_BOTH) {
            return defaultFinalPriceValue;
        }

        return finalPrice;
    }

    const price_tier = _getTierQty1(price_tiers);

    if (!price_tier) {
        return finalPrice;
    }

    if (displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX
            && price_tier.final_price.value > minimalFinalPriceExclTaxValue) {
        return minimalFinalPriceExclTaxValue;
    }

    if (price_tier.final_price.value > minimalFinalPriceValue) {
        return minimalFinalPriceValue;
    }

    // on the category page
    if (isProductCard) {
        // check setting to use tier 1
        const use_tier1 = getStore().getState().SaasConfigReducer.config?.use_tier1 || null;

        // if there are tier prices and not use tier 1
        if (use_tier1 !== '1' && price_tiers.length) {
            // get the lowest price
            const lowestPriceTiersPrice = getLowestPriceTiersPrice(price_tiers);

            // if it is lower then the final price
            if (lowestPriceTiersPrice.final_price.value < finalPrice) {
                // return lowest price
                return lowestPriceTiersPrice.final_price.value;
            }
        }

        if (displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX) {
            return finalPrice;
        }

        // no tier prices or use tier 1, return to normal behaviour, return first tier price from _getTierQty1
        return price_tier.final_price.value;
    }

    return finalPrice;
};

/** @namespace Pwasaas/Util/Price/GetPrice/getHiveFormattedFinalPrice */
export const getHiveFormattedFinalPrice = (props, isProductCard) => {
    const {
        price: {
            minimum_price: {
                final_price: {
                    currency: priceCurrency
                } = {}
            } = {}
        } = {}
    } = props;

    return formatPrice(getFinalPrice(props, isProductCard), priceCurrency);
};
